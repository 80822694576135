import { extendObservable, action } from 'mobx';
import { getProList } from '../service.js';

export default class SupLink {
  constructor() {
    this.reset(true);
  }

  @action
  reset = (init) => {
    const state = {
      supLinkProList: [],
      loading: false, // 是否显示加载状态
      submiting: false,
      isShowFloatHeader: false,
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };

  // 获取suplink产品列表
  @action getProList = (params) => {
    return getProList(params).then(({ success, data }) => {
      if (success) {
        this.supLinkProList = data;
      }
    });
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };

  @action
  hiddenFloatHeader = () => {
    this.isShowFloatHeader = false;
  };

  @action
  showFloatHeader = () => {
    this.isShowFloatHeader = true;
  };
}
