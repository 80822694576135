import { extendObservable, action } from 'mobx';
import { getProList, getBaseConfig } from '../service.js';

export default class xETL {
  constructor() {
    this.reset(true);
  }

  @action
  reset = (init) => {
    const state = {
      loading: false, // 是否显示加载状态
      submiting: false,
      xETLProList: [],
      osName:''
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };

  // 获取x-ETL产品列表
  @action getProList = (params) => {
    return getProList(params).then(({ success, data }) => {
      if (success) {
        this.xETLProList = data;
      }
    });
  };

  // 获取平台配置
  @action getBaseConfig = (params) => {
    return getBaseConfig(params).then(({ code, data }) => {
      if (code==0) {
        this.osName = data?.osName;
      }
    });
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };
}
