/*
 * @Author: luoxia
 * @Date: 2021-03-08 11:28:34
 * @LastEditors: luoxia
 * @LastEditTime: 2022-07-18 13:20:22
 */
import { extendObservable, action } from 'mobx';

import { getProList } from '../service.js';

export default class ESSIndex {
  constructor() {
    this.reset(true);
  }

  // 获取ess产品列表
  @action getProList = (params) => {
    return getProList(params).then(({ success, data }) => {
      if (success) {
        this.ESSProList = data;
      }
    });
  };

  @action
  reset = (init) => {
    const state = {
      loading: false,
      isShowFloatHeader: false,
      ESSProList: [],
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };

  @action showFloatHeader = () => {
    this.isShowFloatHeader = true;
  };

  @action hiddenFloatHeader = () => {
    this.isShowFloatHeader = false;
  };
}
