import { extendObservable, action } from 'mobx';
import {
  getWorkMsgNum,
  getCurrentInfo,
  logout,
  getAPPTypeCode,
  getNavigation,
} from '../service.js';

import { isPlantMate } from '../utils/helper';

export default class Global {
  constructor() {
    this.reset(true);

    this.getAPPTypeCode();
    // 获取导航栏信息
    this.getNavigation();

    this.getWorkMsgNum();
  }

  @action
  reset = (init) => {
    const state = {
      workMsgNum: 0,
      login: false,
      currentInfo: {},
      APPTypeList: [],
      productConfigureList: [],
      APPStoreConfigureList: [],
      solutionConfigure: {},
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };

  // 获取用户当前信息
  @action getCurrentInfo = (params) => {
    return getCurrentInfo(params).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        this.currentInfo = data;
        this.login = true;
      }
      return data;
    });
  };

  // 获取工单数
  @action getWorkMsgNum = (params) => {
    return getWorkMsgNum(params).then(({ success, data }) => {
      if (success) {
        this.workMsgNum = data;
      }
    });
  };

  // 登出
  @action logout = () => {
    return logout().then(({ success }) => {
      if (success) {
        const isPlantMateFlag = isPlantMate();
        if (isPlantMateFlag) {
          const urls = window.location.href.split('?source');
          if (urls.length > 1) {
            window.location.href = urls[0];
          } else {
            const paramUrls = window.location.href.split('&source');
            if (paramUrls.length > 1) {
              window.location.href = paramUrls[0];
            }
          }
        } else {
          window.location.reload();
        }
      }
    });
  };

  // 获取APP类型
  @action getAPPTypeCode = () => {
    return getAPPTypeCode().then(({ succeeded, content }) => {
      if (succeeded) {
        this.APPTypeList = content.dictList;
      }
    });
  };

  // 获取导航栏信息
  @action getNavigation = () => {
    return getNavigation().then(({ success, items }) => {
      if (success) {
        // 只取产品和应用商店的配置

        const productItem = items.find((i) => i.nodeCode === '2Udx');

        const APPStoreItem = items.find((i) => i.nodeCode === 'tXk2');

        const solutionConfigureItem = items.find((i) => i.nodeCode === '6I9B');

        if (productItem && productItem.children) {
          this.productConfigureList = productItem.children;
        }

        if (APPStoreItem && APPStoreItem.children) {
          this.APPStoreConfigureList = APPStoreItem.children;
        }

        if (solutionConfigureItem) {
          this.solutionConfigure = solutionConfigureItem;
        }
      }
    });
  };
}
