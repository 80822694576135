/*
 * @Author: luoxia
 * @Date: 2022-07-18 11:18:40
 * @LastEditors: luoxia
 * @LastEditTime: 2022-08-11 10:46:39
 */
/*
 * @Author: luoxia
 * @Date: 2022-07-18 11:18:40
 * @LastEditors: luoxia
 * @LastEditTime: 2022-07-25 09:45:24
 */
import { extendObservable, action } from 'mobx';
import { getProList } from '../service.js';

export default class APaaS {
  constructor() {
    this.reset(true);
  }

  @action
  reset = (init) => {
    const state = {
      loading: false, // 是否显示加载状态
      submiting: false,
      APaaSProList: [],
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };
  // 获取APaaS产品列表
  @action getProList = (params) => {
    return getProList(params).then(({ success, data }) => {
      if (success) {
        this.APaaSProList = data;
      }
    });
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };
}
