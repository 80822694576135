import AppGlobalModel from '../models/Global.js';
import ExceptionError from '../models/ExceptionError.model.js';
import ESS from '../models/ESS.model.js';
import ECS from '../models/ECS.model.js';
import OCR from '../models/OCR.model.js';
import ResourceBundle from '../models/ResourceBundle.model.js';
import APC from '../models/APC.model.js';
import SupLink from '../models/SupLink.model.js';
import ChannelRecruit from '../models/ChannelRecruit.model.js';
import CloudIntroduce from '../models/CloudIntroduce.model.js';
import Index from '../models/Index.model.js';
import APaaS from '../models/APaaS.model.js';
import xETL from '../models/XETL.model.js';

export default {
  appGlobalModel: new AppGlobalModel(),
  modelExceptionError: new ExceptionError(),
  modelESS: new ESS(),
  modelECS: new ECS(),
  modelOCR: new OCR(),
  modelResourceBundle: new ResourceBundle(),
  modelAPC: new APC(),
  modelSupLink: new SupLink(),
  modelChannelRecruit: new ChannelRecruit(),
  modelCloudIntroduce: new CloudIntroduce(),
  modelIndex: new Index(),
  modelAPaaS: new APaaS(),
  modelxETL: new xETL(),
};
