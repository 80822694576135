/**
 * 通用延时函数
 * @param timeout
 * @returns {Promise<any>}
 */
export const delay = (timeout = 0.3) =>
  new Promise((resolve) => setTimeout(resolve, timeout * 1000));

/**
 * 讲枚举数组转化为枚举对象
 * @param arr
 * @param options {key: 'key',val: 'name'}
 * @returns {{}}
 */
export const exchangeToEnum = (
  arr = [],
  options = {
    key: 'id',
    val: 'name',
  }
) => {
  if (!Array.isArray(arr)) {
    console.error('第一个参数必须为数组');
    return {};
  }
  return arr.reduce((result, item) => {
    result[item[options.key]] = item[options.val];
    return result;
  }, {});
};

/**
 * Quick object check - this is primarily used to tell
 * Objects from primitive values when we know the value
 * is a JSON-compliant type.
 */
export function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}

export function isArray(obj) {
  return Array.isArray(obj);
}

export function isFuc(f) {
  return typeof f === 'function';
}

/**
 * 是否为服务端
 */
export function isServer() {
  return typeof window === 'undefined';
}

/**
 *公用添加默认值的方法
 * @param data 对象 or 数组
 * @param defaultvalue 默认值（替换null和undefined）
 * @returns {Uint8Array | BigInt64Array | any[] | Float64Array | Int8Array | Float32Array | Int32Array | Uint32Array | Uint8ClampedArray | BigUint64Array | Int16Array | Uint16Array|{}|*}
 */
export function generateDefaultValue(data, defaultvalue = '--') {
  if (!isObject(data)) return data;
  const newData = new data.constructor();

  for (let key in Object.getOwnPropertyDescriptors(data)) {
    if (data[key] === null || data[key] === undefined || data[key] === '') {
      newData[key] = defaultvalue;
    } else {
      newData[key] = generateDefaultValue(data[key]);
    }
  }
  return newData;
}

/**
 * 深拷贝
 * @param data
 */
export function deepCopy(data) {
  if (!isObject(data)) return data;
  const newData = new data.constructor();

  for (let key in Object.getOwnPropertyDescriptors(data)) {
    newData[key] = deepCopy(data[key]);
  }
  return newData;
}

/**
 * 深度比较两个数是否相等
 * @param a
 * @param b
 * @returns {boolean}
 */
export function deepCompare(a, b) {
  if (!isObject(a) || !isObject(b)) {
    return a === b;
  }

  const propsA = Object.getOwnPropertyDescriptors(a);
  const propsB = Object.getOwnPropertyDescriptors(b);
  if (Object.keys(propsA).length !== Object.keys(propsB).length) {
    return false;
  }

  return Object.keys(propsA).every((key) => deepCompare(a[key], b[key]));
}

// 根据当前域名设置跳转登录页面的域名
export const getLoginDomain = () => {
  // 兼容服务端渲染
  if (isServer()) return '';
  const hostname = window.location.hostname;
  const hostnameArr = hostname.split('.');
  // 判断是否有www部分, 如果有取数组第二个,如果没有取数组第一个
  if (hostname.indexOf('www') < 0) {
    hostnameArr[0] = 'i'; // i代表登录域的第三级域名 https://www.i.supos.com;
  } else {
    hostnameArr[1] = 'i';
  }

  const loginHostname = hostnameArr.join('.');
  const domain = `http://${loginHostname}`;

  return domain;
};

export const getDomain = (keyword) => {
  // 兼容服务端渲染
  if (isServer()) return;
  const hostname = window.location.hostname;
  const hostnameArr = hostname.split('.');
  // 判断是否有www部分, 如果有取数组第二个,如果没有取数组第一个
  if (hostname.indexOf('www') < 0) {
    hostnameArr[0] = keyword; // i代表登录域的第三级域名 https://www.cloud.supos.com;
  } else {
    hostnameArr[1] = keyword;
  }

  const loginHostname = hostnameArr.join('.');
  const domain = `http://${loginHostname}`;

  return domain;
};

export const getIsIndexPage = () => {
  // 兼容服务端渲染
  if (isServer()) return;
  const pathname = window.location.pathname;
  const isIndexPage =
    pathname === '/' ||
    pathname === '/ess' ||
    pathname === '/ocr' ||
    pathname === '/ecs' ||
    pathname === '/resource-bundle';

  return isIndexPage;
};

// 检查是否是PlantMate跳转过来的链接
export const isPlantMate = () => {
  const { source, userId } = getParams();
  if (source && source.toUpperCase() === 'PLANTMATE' && userId) {
    return true;
  }
  return false;
};

/**
 * 获取url参数对象或 参数key对应的值
 * @param {*} url
 * @param {*} key
 */
export function getParams(url = window.location.href, key) {
  const paramsObj = {};
  const query = url.split('?')[1];
  // 如果没有参数直接返回
  if (!query) return paramsObj;
  query.split('&').forEach((item) => {
    // eslint-disable-line
    const keyValue = item.split('=');
    paramsObj[keyValue[0]] = decodeURIComponent(keyValue[1]); // eslint-disable-line
  });
  return key ? paramsObj[key] : paramsObj;
}

/**
 *
 * @param {*} distance 滚动速度
 */
export function scrollToTop(distance = 150) {
  let id = null;

  const loopScroll = () => {
    let scrollHeight =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (scrollHeight > 0) {
      document.body.scrollTop = document.documentElement.scrollTop =
        scrollHeight - distance;
      id = window.requestAnimationFrame(loopScroll);
    } else {
      window.cancelAnimationFrame(id);
    }
  };

  id = window.requestAnimationFrame(loopScroll);
}

export function scrollHeight() {
  return document.body.scrollTop || document.documentElement.scrollTop;
}

// 获取蓝卓云平台域名
export const getLanzhuoDomain = () => {
  if (isServer()) return;
  let domain = '';
  const href = window.location.href;

  if (href.indexOf('test09') >= 0) {
    domain = `http://cloud-dev.supos.com:19091`;
  } else if (href.indexOf('test08') >= 0) {
    domain = `http://cloud-test.supos.com:19091`;
  } else {
    domain = `https://cloud.supos.com`;
  }

  let key = 'customized-portal_config'
  let getLocal = localStorage.getItem(key) || null
  let portalConfig = JSON.parse(getLocal) || {}
  
  if( portalConfig.userSideConfig ) {
    return portalConfig.userSideConfig.accessAddressUrl;
  }
  return domain
};

// 跳转登录页面
export const goLogin = () => {
  if (isServer()) return;
  const href = window.location.href;
  let key = 'customized-portal_config'
  let getLocal = localStorage.getItem(key) || null
  let portalConfig = JSON.parse(getLocal) || {}
  const loginPageUrl = portalConfig.userSideConfig.loginPageUrl;
  const target = `${loginPageUrl}?siteFromUrl=${encodeURIComponent(href)}`;
  window.location.href = target;
};

// 跳转认证页面
export const goAuthPage = () => {
  // 兼容服务端渲染
  if (isServer()) return '';
  const domain = (DOMAIN_CONFIG && DOMAIN_CONFIG.dev) || 'https://econsole-cloud.supos.com';
  const target = `${domain}/account/authentication/index`;
  window.location.href = target;
};
